import Field from '../Field';
import { fieldPropTypes } from '../propTypes';
import { CurrencyInput } from './Mask/CurrencyInput';

const TextBoxCurrency = props => {
  return (
    <Field
      type="text"
      as={CurrencyInput}
      {...props}
    />
  );
};

TextBoxCurrency.propTypes = fieldPropTypes;

export default TextBoxCurrency;