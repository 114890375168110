import styles from './Calendar.module.scss';
import { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { getDecadeStart, getDecadeEnd } from '@wojtekmaj/date-utils';
import { viewPropTypes } from './propTypes';
import { getCenturyStartYear, getDecadeLabel, getTileClassNames, getTilesGroup, isTileDisabled } from './helpers';

const CenturyView = ({
  activeStartDate,
  formatYear,
  hoveredDate,
  minDate,
  maxDate,
  onClick,
  onMouseOver,
  shouldDisableTile,
  tileContent,
  value,
  valueType,
  daysAllowed,
  specificDatesDisable,
}) => {
  const tilesGroup = useMemo(() => {
    const start = getCenturyStartYear(activeStartDate);
    const end = start + 99;

    return getTilesGroup(start, end, 10).map(tile => getDecadeStart(tile));
  }, [activeStartDate]);

  const transformedMinDate = useMemo(() => minDate && getDecadeStart(minDate), [minDate]);
  const transformedMaxDate = useMemo(() => maxDate && getDecadeEnd(maxDate), [maxDate]);

  return (
    <div className={styles.centuryView}>
      {tilesGroup.map(date => {
        const isDisabled = isTileDisabled(
          date,
          activeStartDate,
          transformedMinDate,
          transformedMaxDate,
          daysAllowed,
          specificDatesDisable,
          'century',
          shouldDisableTile,
        );
        const classNames = getTileClassNames(date, 'decade', value, valueType, hoveredDate);
        const handleMouseOver = !isDisabled && onMouseOver ? event => onMouseOver(date, event) : null;

        return (
          <button
            key={+date}
            tabIndex={isDisabled ? -1 : null}
            className={`${styles.decade} ${classNames}`}
            aria-disabled={isDisabled}
            aria-keyshortcuts="ArrowUp ArrowDown ArrowLeft ArrowRight Space Enter"
            onClick={!isDisabled && onClick ? event => onClick(date, event) : null}
            onFocus={handleMouseOver}
            onMouseOver={handleMouseOver}
            type="button"
          >
            {getDecadeLabel(date, formatYear)}
            {typeof tileContent === 'function' ? tileContent(activeStartDate, date, 'century') : tileContent}
          </button>
        );
      })}
    </div>
  );
};

CenturyView.propTypes = {
  ...viewPropTypes,
  formatYear: PropTypes.func.isRequired,
};

export default CenturyView;
