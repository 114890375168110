export default context => value => {
  if (!value)
    return;

  const { max, prevSize } = context.params;

  if (value instanceof File) {
    if (value.size > max)
      return context.errorMessage;
  }
  else if (value instanceof FileList) {
    let size = 0;
    for (const file of value)
      size += file.size;

    if ((size + prevSize) > max)
      return context.errorMessage;
  }
}; 