import { getYear, getMonthHuman, getDate } from '@wojtekmaj/date-utils';
import DateInput from 'react-date-picker/dist/DateInput';
import DayInput from './inputs/DayInput';
import MonthInput from './inputs/MonthInput';
import YearInput, { defaultMinYear, defaultMaxYear } from './inputs/YearInput';
import MonthSelect from 'react-date-picker/dist/DateInput/MonthSelect';
import { isValidDate } from 'utils/helpers';
import { getYearDiff } from './helpers';
import { toLocaleDate } from 'utils/format';

export default class ExtendedDateInput extends DateInput {
  static localeData = new Map();

  static getDerivedStateFromProps(nextProps, prevState) {
    const localeDataKey = `${nextProps.locale}_${nextProps.maxDetail}`;
    if (!ExtendedDateInput.localeData.get(localeDataKey)) {
      const yearDiff = getYearDiff(nextProps.locale);
      const placeholder = getPlaceholder(nextProps.locale, yearDiff);
      ExtendedDateInput.localeData.set(localeDataKey, { placeholder, yearDiff });
    }

    if (nextProps.value && isValidDate(nextProps.value)) {
      if (
        nextProps.minDate && nextProps.value < nextProps.minDate
        || nextProps.maxDate && nextProps.value > nextProps.maxDate
      ) {
        const year = getYear(nextProps.value);
        const month = getMonthHuman(nextProps.value);
        const day = getDate(nextProps.value);

        if (prevState.value && (year !== prevState.year || month !== prevState.month || day !== prevState.day))
          return prevState;

        return { year, month, day, value: nextProps.value };
      }
    }

    if (nextProps.value && !isValidDate(nextProps.value))
      return { hasInvalidDate: true };

    if (!nextProps.value && prevState.hasInvalidDate)
      return { day: null, month: null, year: null, hasInvalidDate: false };

    let prevStateCore = prevState;
    if (nextProps.value && prevState.value && prevState.hasInvalidDate)
      prevStateCore = { ...prevStateCore, value: null };

    const nextState = DateInput.getDerivedStateFromProps(nextProps, prevStateCore);

    if (prevState.hasInvalidDate)
      nextState.hasInvalidDate = false;

    return nextState;
  }

  get localeDataKey() {
    return `${this.props.locale}_${this.props.maxDetail}`;
  }

  get placeholder() {
    if (this.props.format)
      return this.props.format;

    return ExtendedDateInput.localeData.get(this.localeDataKey).placeholder;
  }

  get yearDiff() {
    return ExtendedDateInput.localeData.get(this.localeDataKey).yearDiff;
  }

  get commonInputProps() {
    const { className, isCalendarOpen, required, allowTypeDate } = this.props;
    let { disabled } = this.props;
    if(allowTypeDate === false)
      disabled = true;

    return {
      className,
      disabled,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      onKeyUp: this.onKeyUp,
      // This is only for showing validity when editing.
      required: required || isCalendarOpen,
      itemRef: (ref, name) => {
        // Save a reference to each input field.
        this[`${name}Input`] = ref;
      },
    };
  }

  componentWillUnmount() {
    clearTimeout(this.onChangeTimeout);
  }

  onKeyDown = event => {
    switch (event.key) {
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Left':
      case 'Right':
      case this.divider:
        if (event.key !== this.divider && event.shiftKey)
          return;

        event.preventDefault();

        const { target: input } = event;
        const isLeftArrowKey = event.key === 'ArrowLeft' || event.key === 'Left';
        const property = isLeftArrowKey ? 'previousElementSibling' : 'nextElementSibling';
        const nextInput = findInput(input, property);
        focus(nextInput);
        return;
      case ' ':
      case 'Spacebar':
        event.preventDefault();
        return;
      default:
        return;
    }
  };

  onKeyUp = event => {
    const { key, target: input } = event;

    const isNumberKey = !isNaN(parseInt(key !== 'Unidentified' ? key : input.value.slice(-1), 10));
    if (!isNumberKey)
      return;

    const maxLength = +input.getAttribute('maxLength');
    if (input.value.length === maxLength) {
      const property = 'nextElementSibling';
      const nextInput = findInput(input, property);
      focus(nextInput);
    }
  };

  onChange = (event, name, value) => {
    if (event) {
      name = event.target.name;
      value = event.target.value;
    }

    if (name == null)
      return;

    this.setState(() => {
      let newValue = value && !isNaN(value) ? parseInt(value, 10) : null;
      if (newValue && name === 'year')
        newValue -= this.yearDiff;

      return { [name]: newValue };
    });

    clearTimeout(this.onChangeTimeout);
    this.onChangeTimeout = setTimeout(this.onChangeExternal, 50);
  };

  onChangeExternal = () => {
    const { onChange } = this.props;
    if (!onChange)
      return;

    if (!this.dayInput || !this.monthInput || !this.yearInput)
      return;

    if (!this.dayInput.value && !this.monthInput.value && !this.yearInput.value) {
      onChange(null, false);
      return;
    }

    const day = +this.dayInput.value;
    const isDayValid = day >= +this.dayInput.dataset.min && day <= +this.dayInput.dataset.max;

    const month = +this.monthInput.value;
    const isMonthValid = month >= +this.monthInput.dataset.min && month <= +this.monthInput.dataset.max;

    const year = +this.yearInput.value;
    const isYearValid = year >= defaultMinYear && year <= defaultMaxYear;

    if (isDayValid && isMonthValid && isYearValid)
      onChange(new Date(year - this.yearDiff, month - 1, day), false);
    else
      onChange(new Date(NaN), false);
  };

  // eslint-disable-next-line react/no-multi-comp
  renderDay = (currentMatch, index) => {
    const {
      autoFocus,
      dayAriaLabel,
      dayPlaceholder,
      id,
    } = this.props;
    const { day, month, year } = this.state;

    if (currentMatch && currentMatch.length > 2)
      throw new Error(`Unsupported token: ${currentMatch} `);

    return (
      <DayInput
        key="day"
        id={index === 0 ? id : null}
        {...this.commonInputProps}
        ariaLabel={dayAriaLabel}
        autoFocus={index === 0 && autoFocus}
        month={month}
        placeholder={dayPlaceholder}
        value={day}
        year={year}
        allowEmpty={!month && !year}
      />
    );
  };

  // eslint-disable-next-line react/no-multi-comp
  renderMonth = (currentMatch, index) => {
    const {
      autoFocus,
      locale,
      monthAriaLabel,
      monthPlaceholder,
      id,
    } = this.props;
    const { day, month, year } = this.state;

    if (currentMatch && currentMatch.length > 4)
      throw new Error(`Unsupported token: ${currentMatch} `);

    if (currentMatch.length > 2) {
      return (
        <MonthSelect
          key="month"
          id={index === 0 ? id : null}
          {...this.commonInputProps}
          ariaLabel={monthAriaLabel}
          autoFocus={index === 0 && autoFocus}
          locale={locale}
          placeholder={monthPlaceholder}
          short={currentMatch.length === 3}
          value={month}
          year={year}
        />
      );
    }

    return (
      <MonthInput
        key="month"
        id={index === 0 ? id : null}
        {...this.commonInputProps}
        ariaLabel={monthAriaLabel}
        autoFocus={index === 0 && autoFocus}
        placeholder={monthPlaceholder}
        value={month}
        allowEmpty={!day && !year}
      />
    );
  };

  // eslint-disable-next-line react/no-multi-comp
  renderYear = (_currentMatch, index) => {
    const { autoFocus, yearAriaLabel, yearPlaceholder, id, maxDate, minDate } = this.props;
    const { day, month, year } = this.state;

    return (
      <YearInput
        key="year"
        id={index === 0 ? id : null}
        {...this.commonInputProps}
        maxDate={maxDate}
        minDate={minDate}
        ariaLabel={yearAriaLabel}
        autoFocus={index === 0 && autoFocus}
        placeholder={yearPlaceholder}
        value={year}
        allowEmpty={!day && !month}
        yearDiff={this.yearDiff}
      />
    );
  };
}

ExtendedDateInput.defaultProps = {
  maxDetail: 'month',
  name: 'date',
  returnValue: 'start',
  minDate: new Date(defaultMinYear, 0, 1),
  maxDate: new Date(defaultMaxYear, 0, 1),
};

function isValidInput(element) {
  return element.tagName === 'INPUT' && element.dataset.type === 'number';
}

function findInput(element, property) {
  let nextElement = element;
  do {
    nextElement = nextElement[property];
  } while (nextElement && !isValidInput(nextElement));
  return nextElement;
}

function focus(element) {
  if (element) {
    element.focus();
  }
}

function getPlaceholder(locale, yearDiff) {
  const year = 2017;
  const monthIndex = 11;
  const day = 18;
  const date = new Date(year, monthIndex, day);

  const formattedDate = toLocaleDate(date, locale);

  return formattedDate
    .replace(/\u200F/g, '') // Remove unicode right-to-left marks.
    .replace(/\u200E/g, '') // Fix to support IE which adds unicode symbols.
    .replace(day, 'd')
    .replace(monthIndex + 1, 'M')
    .replace(year + yearDiff, 'y');
}
