import styles from './Calendar.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getYear, getMonthStart, getMonthEnd } from '@wojtekmaj/date-utils';
import { viewPropTypes } from './propTypes';
import { getTileClassNames, getTilesGroup, isTileDisabled } from './helpers';

const YearView = ({
  activeStartDate,
  formatMonth,
  formatMonthYear,
  hoveredDate,
  minDate,
  maxDate,
  onClick,
  onMouseOver,
  tileContent,
  shouldDisableTile,
  value,
  valueType,
  daysAllowed,
  specificDatesDisable,
}) => {
  const tilesGroup = useMemo(() => {
    const year = getYear(activeStartDate);
    return getTilesGroup(0, 11).map(tile => new Date(year, tile, 1));
  }, [activeStartDate]);

  const transformedMinDate = useMemo(() => minDate && getMonthStart(minDate), [minDate]);
  const transformedMaxDate = useMemo(() => maxDate && getMonthEnd(maxDate), [maxDate]);

  return (
    <div className={styles.yearView}>
      {tilesGroup.map(date => {
        const isDisabled = isTileDisabled(
          date,
          activeStartDate,
          transformedMinDate,
          transformedMaxDate,
          daysAllowed,
          specificDatesDisable,
          'year',
          shouldDisableTile,
        );
        const classNames = getTileClassNames(date, 'month', value, valueType, hoveredDate);
        const handleMouseOver = !isDisabled && onMouseOver ? event => onMouseOver(date, event) : null;

        return (
          <button
            key={+date}
            tabIndex={isDisabled ? -1 : null}
            className={`${styles.month} ${classNames}`}
            aria-disabled={isDisabled}
            aria-keyshortcuts="ArrowUp ArrowDown ArrowLeft ArrowRight Space Enter"
            onClick={!isDisabled && onClick ? event => onClick(date, event) : null}
            onFocus={handleMouseOver}
            onMouseOver={handleMouseOver}
            type="button"
          >
            <abbr aria-label={formatMonthYear(date)}>
              {formatMonth(date)}
            </abbr>
            {typeof tileContent === 'function' ? tileContent(activeStartDate, date, 'year') : tileContent}
          </button>
        );
      })}
    </div>
  );
};

YearView.propTypes = {
  ...viewPropTypes,
  formatMonth: PropTypes.func.isRequired,
  formatMonthYear: PropTypes.func.isRequired,
};

export default YearView;
