import styles from '../Calendar.module.scss';
import { getRange } from './dates';
import { PeriodType } from '../constants';
import type { DateInput } from '@wojtekmaj/date-utils';

export const DaysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/**
 * Changes the hour in a Date to ensure right date formatting applied even if DST is messed up.
 * Workaround for bug in WebKit and Firefox with historical dates. For more details, please see:
 * https://bugs.chromium.org/p/chromium/issues/detail?id=750465
 * https://bugzilla.mozilla.org/show_bug.cgi?id=1385643
 * @param {DateInput} date - target date.
 * @returns {Date} - work safe date.
 */
export function toSafeHour(date: DateInput): Date {
  const safeDate = new Date(date);
  return new Date(safeDate.setHours(12));
}

/**
 * Creates an array of day/month/year values (depending on currently used calendar view - century/decade/year/month)
 * which will be used to render tiles in different calendar views.
 * @param {number} start - range start value.
 * @param {number} end - range end value.
 * @param {number} step - step used for defining values which should be rendered between start and end of range.
 * @returns {number[]} - tiles group array with appropriate values.
 */
export function getTilesGroup(start: number, end: number, step = 1): number[] {
  const tiles = [];
  for (let point = start; point <= end; point += step)
    tiles.push(point);

  return tiles;
}

/**
 * Checks if disabled status should be applied for a tile.
 * @param {Date} date - target date.
 * @param {Date} activeStartDate - current calendar active start date.
 * @param {Date} minDate - minimum date for a given period.
 * @param {Date} maxDate - maximum date for a given period.
 * @param {string} view - type of calendar (ISO 8601, US, Arabic or Hebrew).
 * @param {function} shouldDisableTile - custom function for checking if disabled status should be applied.
 * @returns {boolean} - result of performed checks.
 */
export function isTileDisabled(
  date: Date,
  activeStartDate: Date,
  minDate: Date,
  maxDate: Date,
  daysAllowed: string[],
  specificDatesDisable: Date[],
  view: PeriodType,
  shouldDisableTile?: (activeStartDate: Date, date: Date, view: string) => boolean,
): boolean {
  let day = DaysOfWeek[date.getDay()];

  if (minDate > date)
    return true;

  if (maxDate < date)
    return true;

  if(isInArray(specificDatesDisable, date))
    return true;

  if(daysAllowed && !daysAllowed.includes(day))
    return true;

  if (shouldDisableTile)
    return shouldDisableTile(activeStartDate, date, view);

  return false;
}

function isInArray(array:Date[] | null, value:Date) {
  if(!array || array.length<=0)
    return false;
  return !!array.find(item => {return item.getTime() == value.getTime()});
}

type EdgeDate = Date | null | undefined;

/**
 * Normalize date so it will be part of given range created by min and max values.
 * @param {Date} date - target date.
 * @param {EdgeDate} minDate - range start.
 * @param {EdgeDate} maxDate - range end.
 * @returns {Date} - normalized date.
 */
export function normalizeDate(date: Date, minDate: EdgeDate, maxDate: EdgeDate): Date {
  if (minDate && minDate > date)
    return minDate;

  if (maxDate && maxDate < date)
    return maxDate;

  return date;
}

/**
 * Checks if value belongs to a given range.
 * @param {Date} value - target date.
 * @param {Date[]} range - target dates range array.
 * @returns {boolean} - result of the check.
 */
export function isValueWithinRange(value: Date, range: Date[]): boolean {
  const [min, max] = range;
  return min <= value && max >= value;
}

/**
 * Checks if one dates range includes other.
 * @param {Date[]} greaterRange - first, greater range.
 * @param {Date[]} smallerRange - second, smaller range.
 * @returns {boolean} - result of the check.
 */
export function isRangeIncudesOtherRange(greaterRange: Date[], smallerRange: Date[]): boolean {
  const [greaterRangeStart, greaterRangeEnd] = greaterRange;
  const [smallerRangeStart, smallerRangeEnd] = smallerRange;
  return greaterRangeStart < smallerRangeStart && greaterRangeEnd > smallerRangeEnd;
}

/**
 * Checks if two dates ranges overlap.
 * @param {Date[]} range1 - first dates range.
 * @param {Date[]} range2 - second dates range.
 * @returns {boolean} - result of the check.
 */
export function areRangesOverlapping(range1: Date[], range2: Date[]): boolean {
  const [range1Start, range1End] = range1;
  return isValueWithinRange(range1Start, range2) || isValueWithinRange(range1End, range2);
}

/**
 * Gets currently marked for selection range in calendar dates.
 * @param {Date[]} valueRange - array with previously marked for selection range.
 * @param {Date} hoveredDate - date which is now been marked for selection by user.
 * @returns {Date[]} - array with start and end dates of marked for selection range.
 */
function getMarkedForSelectionRange(valueRange: Date[], hoveredDate: Date): Date[] {
  const [start, end] = valueRange;
  return hoveredDate > end ? [end, hoveredDate] : [hoveredDate, start];
}

/**
 * Gets class name for tile indicating if it is now been selected or if selected value is within the dates range it represents.
 * @param {Date[]} valueRange - array with start and end dates for calendar value dates range.
 * @param {Date[]} dateRange - array with start and end dates for tile group dates range.
 * @returns {string} - class name which should be applied for a tile.
 */
function getValueBasedClassName(valueRange: Date[], dateRange: Date[]): string {
  if (isRangeIncudesOtherRange(valueRange, dateRange))
    return styles.active;
  else if (areRangesOverlapping(valueRange, dateRange))
    return styles.hasActive;

  return '';
}

/**
 * Gets range related class names for a tile.
 * @param {Date[]} valueRange - array with start and end dates for calendar value dates range.
 * @param {Date[]} dateRange - array with start and end dates for tile dates range.
 * @param {boolean} isHoverRange - indicates which range styles should be used - general or hover.
 * @returns {string} - class names which should be applied for a tile.
 */
function getRangeClassNames(valueRange: Date[], dateRange: Date[], isHoverRange = false): string {
  const rangesOverlap = areRangesOverlapping(dateRange, valueRange);
  if (!rangesOverlap)
    return '';

  const classNames = [isHoverRange ? styles.hover : styles.range];
  const isRangeStart = isValueWithinRange(valueRange[0], dateRange);
  if (isRangeStart)
    classNames.push(isHoverRange ? styles.hoverStart : styles.rangeStart);

  const isRangeEnd = isValueWithinRange(valueRange[1], dateRange);
  if (isRangeEnd)
    classNames.push(isHoverRange ? styles.hoverEnd : styles.rangeEnd);

  if (isRangeStart && isRangeEnd)
    classNames.push(isHoverRange ? styles.hoverBothEnds : styles.rangeBothEnds);

  return classNames.join(' ');
}

type DateValueType = Date | Date[] | undefined;

/**
  * Gets class names which should be applied for a tile.
  * @param {DateValueType} date - tile date value.
  * @param {string} rangeType - range type for a date represented by tile.
  * @param {DateValueType} value - calendar date value.
  * @param {string} valueType - range type for a calendar value date.
  * @param {Date} hoveredDate - date which is currently being hovered by user.
  * @returns {string} - class names which should be applied for a tile.
 */
export function getTileClassNames(date: DateValueType, rangeType: PeriodType, value: DateValueType, valueType: PeriodType, hoveredDate: Date | null): string {
  let classNames: string = styles.tile;
  if (!date)
    return classNames;

  let dateRange: Date[];
  if (Array.isArray(date)) {
    dateRange = date;
  } else {
    if (!rangeType)
      throw new Error('getTileClassNames(): Unable to get tile activity classes because one or more required arguments were not passed.');

    dateRange = getRange(rangeType, date);
  }

  if (isValueWithinRange(new Date(), dateRange))
    classNames += ` ${styles.now}`;

  if (!value)
    return classNames;

  let valueRange: Date[];
  if (Array.isArray(value)) {
    valueRange = value;
  } else {
    if (!valueType)
      throw new Error('getTileClassNames(): Unable to get tile activity classes because one or more required arguments were not passed.');

    valueRange = getRange(valueType, value);
  }

  const valueBasedClassName = getValueBasedClassName(valueRange, dateRange);
  if (valueBasedClassName)
    classNames += ` ${valueBasedClassName}`;

  const valueRangeClassNames = getRangeClassNames(valueRange, dateRange);
  if (valueRangeClassNames)
    classNames += ` ${valueRangeClassNames}`;

  if (hoveredDate) {
    const hoveredRange = getMarkedForSelectionRange(valueRange, hoveredDate);
    const hoverRangeClassNames = getRangeClassNames(hoveredRange, dateRange, true);
    if (hoverRangeClassNames)
      classNames += ` ${hoverRangeClassNames}`;
  }

  return classNames;
}